<template>
  <div style="width: 100%; height: 100%; padding-bottom: 40px" class="fadeIn">
    <van-nav-bar
      title="原因组设置"
      left-text="返回"
      right-text="添加"
      left-arrow
      @click-left="$router.go(-1)"
      @click-right="show = true"
    />
    <div class="GroupBody">
      <div class="box" v-for="item in groupList" :key="item.causeGroupId">
        <van-row
          style="
            padding: 10px;
            background-color: #f7f8fa;
            border-bottom: 1px solid #dee1e6;
            height: 30%;
          "
        >
          <van-col span="16">
            <h4 style="display: inline-block; margin-right: 5px">
              {{ item.causeGroupName }}
            </h4>
            <van-icon
              name="edit"
              color="#1989fa"
              size="0.4rem"
              @click="editName(item)"
          /></van-col>
          <van-col span="8"
            ><span style="font-size: 12px; color: #a2a7a5"
              >分组ID:{{ item.causeGroupId }}</span
            >
            <span
              style="font-size: 12px; color: #1989fa; float: right"
              @click="deleteClassify(item.causeGroupId)"
              >删除分组</span
            ></van-col
          >
        </van-row>
        <van-row style="height: 70%">
          <van-col
            :span="10"
            style="border-right: 1px solid #e7e7e8; height: 100%"
            class="elCol"
          >
            <span style="color: #989795; font-size: 12px; margin-right: 10%"
              >关联原因</span
            ><span
              style="color: #1989fa; font-size: 12px"
              @click="editCauseList(item)"
              >设置</span
            >
            <p style="margin-top: 8%">
              <span style="font-size: 12px">已关联原因：</span>
              <van-tag plain type="primary"
                >{{ item.causeGroupRelCount }}个原因</van-tag
              >
            </p>
          </van-col>
          <van-col :soan="14" class="elCol">
            <span style="color: #989795; font-size: 12px; margin-right: 10%"
              >关联项目</span
            ><span
              style="color: #1989fa; font-size: 12px"
              @click="editProjectList(item)"
              >配置</span
            >
            <p style="margin-top: 8%">
              <span style="font-size: 12px">已关联设备：</span>
              <van-tag plain type="primary"
                >{{ item.projectIdCount }}台设备</van-tag
              >
            </p>
          </van-col>
        </van-row>
      </div>
    </div>
    <div style="position: fixed; bottom: 0; right: 0; width: 100%">
      <van-pagination
        v-model="causeGroupPage.current"
        :total-items="causeGroupPage.total"
        :items-per-page="causeGroupPage.size"
        :show-page-size="5"
        force-ellipses
        @change="currentChange"
      />
    </div>
    <van-popup
      v-model="show"
      round
      position="top"
      :style="{ height: '30%', background: '#f7f8fa' }"
      closeable
      @close="close"
    >
      <h4 style="margin: 20px">新增分组</h4>
      <van-divider
        :style="{
          borderColor: '#b1b1b1',
          padding: '0 16px',
          margin: '0 2px',
        }"
      />
      <van-form style="width: 70%; margin: 0 auto">
        <van-field
          v-model="formGroup.causeGroupName"
          label="分组名称"
          placeholder="请输入分组名称"
          maxlength="15"
        />
        <div style="margin: 30px 0 0 70%; width: 30%">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            size="mini"
            @click="onsubmit"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <!-- 原因设置弹出层 -->
    <van-popup
      v-model="CausePopup"
      round
      position="bottom"
      :style="{ height: '80%' }"
      @close="clearEdit"
    >
      <van-row style="padding: 10px 10px">
        <van-col style="position: fixed">
          <van-icon name="close" size="0.5rem" @click="clearEdit" />
        </van-col>
        <van-col @click="createEdit" style="position: fixed; right: 30px">
          确定
        </van-col>
      </van-row>
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 20px"
        height="90%"
      >
        <el-table-column
          prop="causeCode"
          label="功能码"
          align="center"
          width="60%"
        >
        </el-table-column>
        <el-table-column prop="name" label="选择原因" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.causeId" placeholder="请选择">
              <el-option
                v-for="item in causeList"
                :key="item.causeId"
                :label="item.causeName"
                :value="item.causeId"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </van-popup>
    <!-- 关联设备弹出层 -->
    <van-popup
      v-model="projectPopup"
      round
      position="bottom"
      :style="{ height: '80%' }"
      @close="clearproject"
    >
      <van-row style="padding: 10px 10px">
        <van-col style="position: fixed">
          <van-icon name="close" size="0.5rem" @click="clearproject" />
        </van-col>
        <van-col
          @click="addCauseSharingvalue"
          style="position: fixed; right: 30px"
        >
          确定
        </van-col>
      </van-row>
      <el-transfer
        style="text-align: left; margin: 10% 25%"
        :titles="['全部设备', '已关联设备']"
        filterable
        v-model="CauseSharingvalue"
        :data="CauseSharingdata"
        :props="{ key: 'projectId', label: 'projectName' }"
      ></el-transfer>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      CauseSharingdata: [],
      CauseSharingvalue: [],
      causeList: [],
      itemData: {},
      projectPopup: false,
      CausePopup: false,
      groupList: [],
      show: false,
      formGroup: {
        causeGroupName: "",
        projectGroupId: this.$store.state.projectGroupId * 1,
      },
      causeGroupPage: {
        current: 1,
        size: 4,
        projectGroupId: this.$store.state.projectGroupId * 1,
        total: 0,
      },
      tableData: [
        { causeCode: 1, causeId: "" },
        { causeCode: 2, causeId: "" },
        { causeCode: 3, causeId: "" },
        { causeCode: 4, causeId: "" },
        { causeCode: 5, causeId: "" },
        { causeCode: 6, causeId: "" },
        { causeCode: 7, causeId: "" },
        { causeCode: 8, causeId: "" },
        { causeCode: 9, causeId: "" },
        { causeCode: 10, causeId: "" },
        { causeCode: 11, causeId: "" },
        { causeCode: 12, causeId: "" },
        { causeCode: 13, causeId: "" },
        { causeCode: 14, causeId: "" },
        { causeCode: 15, causeId: "" },
      ],
    };
  },
  created() {
    this.getGroupList();
  },
  methods: {
    clearproject() {
      this.CauseSharingvalue = [];
      this.projectPopup = false;
    },
    // 添加关联的设备
    addCauseSharingvalue() {
      let data = {
        causeGroupId: this.itemData.causeGroupId,
        projectGroupId: this.itemData.projectGroupId * 1,
        causeGroupName: this.itemData.causeGroupName,
        projectIdList: this.CauseSharingvalue,
      };
      this.$api.setCause.updateCauseGroup(data).then((res) => {
        if (res.code == 200) {
          this.$toast.success(res.msg);
          this.clearproject();
          this.getGroupList();
        }
      });
    },
    createEdit() {
      let addCause = this.tableData.filter((it) => it.causeId);
      let data = {
        causeGroupId: this.itemData.causeGroupId,
        projectGroupId: this.itemData.projectGroupId,
        causeGroupName: this.itemData.causeGroupName,
        causeGroupRelList: [...addCause],
      };
      this.$api.setCause.updateCauseGroup(data).then((res) => {
        if (res.code == 200) {
          this.$toast.success(res.msg);
          this.clearEdit();
          this.getGroupList();
        }
      });
    },
    //取消关联原因
    clearEdit() {
      this.CausePopup = false;
      this.tableData = [
        { causeCode: 1, causeId: "" },
        { causeCode: 2, causeId: "" },
        { causeCode: 3, causeId: "" },
        { causeCode: 4, causeId: "" },
        { causeCode: 5, causeId: "" },
        { causeCode: 6, causeId: "" },
        { causeCode: 7, causeId: "" },
        { causeCode: 8, causeId: "" },
        { causeCode: 9, causeId: "" },
        { causeCode: 10, causeId: "" },
        { causeCode: 11, causeId: "" },
        { causeCode: 12, causeId: "" },
        { causeCode: 13, causeId: "" },
        { causeCode: 14, causeId: "" },
        { causeCode: 15, causeId: "" },
      ];
    },
    editName(item) {
      this.formGroup = item;
      this.show = true;
    },
    close() {
      this.show = false;
      this.formGroup = {
        GruopName: "",
        projectGroupId: this.$store.state.projectGroupId * 1,
      };
    },
    async onsubmit() {
      try {
        let res = {};
        if (this.formGroup.causeGroupId) {
          let data = {
            causeGroupId: this.formGroup.causeGroupId,
            projectGroupId: this.formGroup.projectGroupId,
            causeGroupName: this.formGroup.causeGroupName,
          };
          res = await this.$api.setCause.updateCauseGroup(data);
        } else {
          res = await this.$api.setCause.addCauseGroup(this.formGroup);
        }
        if (res.code == 200) {
          this.close();
          this.getGroupList();
          this.$toast.success(res.msg);
        }
      } catch (e) {
        this.$toast.fail(e.msg);
      }
    },
    // 原因设置
    async editCauseList(item) {
      this.itemData = item;
      await this.$api.setCause.getCauseList(item.causeGroupId).then((res) => {
        if (res.code == 200) {
          this.CausePopup = true;
          res.data.forEach((item) => {
            this.tableData.find((it) => {
              if (item.causeCode == it.causeCode) {
                it.causeId = item.causeId;
                return true;
              }
            });
          });
        }
      });
    },
    //获取所有原因
    getAllCause() {
      let data = {
        projectGroupId: this.$store.state.projectGroupId * 1,
        causeName: "",
      };
      this.$api.setCause.getAllCause(data).then((res) => {
        if (res.code == 200) {
          this.causeList = res.data;
        }
      });
    },
    //设备设置
    async editProjectList(item) {
      this.CauseSharingvalue = [];
      this.itemData = item;
      let data = {
        projectName: "",
        projectGroupId: this.$store.state.projectGroupId * 1,
      };
      let added = [];
      await this.$api.setCause.getListAll(47).then((res) => {
        if (res.code == 200) {
          added = res.data;
        }
      });
      await this.$api.project.getProjectList(data).then((res) => {
        if (res.code == 200) {
          this.CauseSharingdata = res.data.filter(
            (item) => item.projectType == "C" || item.projectType == "G"
          );
          added.forEach((item) => {
            this.CauseSharingdata.find((it) => {
              if (item == it.projectId) {
                it.disabled = true;
                return true;
              }
            });
          });
          this.projectPopup = true;
        }
      });
      await this.$api.setCause
        .getCauseGroupList(item.causeGroupId)
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              this.CauseSharingvalue.push(item.projectId);
            });
            this.CauseSharingvalue.forEach((item) => {
              this.CauseSharingdata.find((it) => {
                if (item == it.projectId) {
                  it.disabled = false;
                  return true;
                }
              });
            });
          }
        });
    },
    deleteClassify(id) {
      this.$dialog
        .confirm({
          title: "删除操作",
          message: "此操作会永久删除文件，继续吗？",
        })
        .then(() => {
          this.$api.setCause.delCauseGroup(id).then((res) => {
            if (res.code == 200) {
              this.$toast.success("删除成功!");
              this.getGroupList();
            }
          });
        })
        .catch(() => {
          this.$toast.fail("取消删除");
        });
    },
    getGroupList() {
      this.$api.setCause.getCauseGroup(this.causeGroupPage).then((res) => {
        if (res.code == 200) {
          this.causeGroupPage.total = res.data.total;
          this.groupList = res.data.records;
        }
      });
    },
    currentChange(val) {
      this.causeGroupPage.current = val;
      this.getGroupList();
    },
  },
  mounted() {
    this.getAllCause();
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.GroupBody {
  width: 100%;
  height: 100%;
  padding: 0 5px;
}
.GroupBody .box {
  text-align: left;
  margin-top: 10px;
  width: 100%;
  height: 20%;
  border-radius: 10px;
  border: 1px solid #dee1e6;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.elCol {
  padding: 20px 0 0 20px;
  font-size: 14px !important;
}
::v-deep .el-transfer__buttons {
  margin-top: 10px !important;
}
</style>
